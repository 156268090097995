import React from "react";
import * as styles from "./pdf-email-form.module.css";

function PDFEmailForm({ pdf }) {
    let tag = ''
    switch (pdf.asset.title) {
        case 'Chicopee Winter Places':
            tag = '6241240';
            break;
        case 'Patronicity Annual Report 2021':
            tag = '6264024';
            break;
        case 'Case Study Ruff Tumble Dog Park':
            tag = '6264028';
            break;
        case 'Case Study Cultivate Coffee Tap House':
            tag = '6264032';
            break;
        case 'Case Study 6102 ART PARK':
            tag = '6264036';
            break;
        case 'Case Study Batesville Main Street':
            tag = '6264040';
            break;
        case 'Case Study 100 Bikes for Better Access':
            tag = '6264044';
            break;
        case 'Case Study Dabls African Bead Museum':
            tag = '6264048';
            break;
        case 'Case Study Popps Emporium':
            tag = '6241444';
            break;
        case 'Case Study Huss Imaginarium':
            tag = '6264052';
            break;
        case 'Case Study Eaton Rapids':
            tag = '6264056';
            break;
        case 'Case Study Kerouac Park':
            tag = '6264060';
            break;
        case 'Case Study Eagle Street':
            tag = '6264064';
            break;
        case 'Case Study Revitalize Congress Street':
            tag = '6264068';
            break;
        case 'Case Study Putnam County Mural Project':
            tag = '6264072';
            break;
        case 'Case Study PROVA Brockton':
            tag = '6264076';
            break;
        case 'Winter Places 2.0 2021':
            tag = '6264080';
            break;
        case 'Winter Places Design Guide for Winter Activation 2020':
            tag = '6264084';
            break;
        case 'Patronicity Small Biz Strategy Guide':
            tag = '6264088';
            break;
        case 'Crowdfunding Strategy Guide':
            tag = '6264092';
            break;
        case 'Case Study The Corner Spot':
            tag = '6264096';
            break;
        case 'Case Study Ypsilanti Farmers MarketPlace':
            tag = '6264100';
            break;
        case 'Case Study The Croswell Theater':
            tag = '6264104';
            break;
        case 'Patronicity Annual Report 2022':
            tag = '6377524';
            break;
        case 'PSCP Annual Report 2022':
            tag = '6377528';
            break;
        case 'Patronicity Project Creator Donor List Template':
            tag = '6380320';
            break;
        case 'Patronicity Resources Budget Template':
            tag = '6380324';
            break;
        case 'VT Brattleboro High Street Case Study':
            tag = '6381308';
            break;
        case 'Creating Places 2023 Annual Report':
            tag = '6516376';
            break;
        case 'Public Spaces Community Places 2023 Annual Report':
            tag = '6516380';
            break;
        case 'Case Study Guilford Community Park':
            tag = '6516384';
            break;
        case 'Community Match Fund 2023 Annual Report':
            tag = '6516876';
            break;
        case '2023 Better Places Annual Report - Patronicity - Compressed':
            tag = '6516812';
            break;
        case 'Biz-M-Power 2023 Annual Report - Compressed':
            tag = '6517332';
            break;
        case 'Patronicity 2023 Annual Report':
            tag = '6517540';
            break;
        case 'Case Study West Hartford Tree Project':
            tag = '23457176';
            break;
        case 'Case Study GoodieKrunch':
            tag = '23457180';
            break;
        case '2024 Biz-M-Power Annual Report - Compressed':
            tag = '23767766';
            break;
        case '2024 Creating Places Annual Report - Compressed':
            tag = '23767769';
            break;
        case '2024 Public Spaces Community Places Annual Report - Compressed':
            tag = '23767767';
            break;
        default:
            tag = '';
    }

    return (
        <div id="mc_embed_pdf">
            <form action="https://benchconsulting.us19.list-manage.com/subscribe/post?u=9987e252175671e64424db8c2&amp;id=e69ee2caba" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div id="mc_embed_pdf_scroll" className={styles.pdfEmailDownload}>
                    <h2>Get Access</h2>
                    <label for="mce-EMAIL">Subscribe to our email newsletter to receive this resource to your inbox.</label>
                    <div hidden="true"> <input type="hidden" name="tags" value={`6241040, ${tag}`}></input></div>
                    <input type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required></input>
                    {/*  real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                    <div hidden="true"><input type="text" name="b_9987e252175671e64424db8c2_e69ee2caba" tabindex="-1" value=""></input></div>
                    <div class="clear btn-container"><input type="submit" value="Subscribe Now" name="subscribe" id="mc-embedded-subscribe" className={styles.button}></input></div>
                </div>
            </form>
        </div>
    );
};

export default PDFEmailForm;

